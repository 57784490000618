.p_inner_text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #b5b3bc;
}
.p_outer_larger_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  /* identical to box height, or 100% */
  letter-spacing: 0.01em;
  /* Text/Text 1 */
  color: #f2f0ff;
  text-shadow: 2px 4px 12px rgba(0, 0, 0, 0.64);
}
.wrapper_error_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  /* identical to box height, or 100% */
  letter-spacing: 0.01em;
  /* Text/Text 1 */
  color: #f2f0ff;
}

.divider_line {
  width: 100%;
  height: 1.5px;
  background-color: #b5b3bc;
  margin: 1.5rem 0;
}

.row_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.p_inner_text_clickable:hover {
  cursor: pointer;
}

.hide_button {
  background: #161616;
  border-radius: 20px;
  width: 75px;
  height: 82px;
}

.box_outer_info {
  height: calc(100vh - 95px);
  background-color: #161616;
  border-radius: 20px;
  border: 1px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  overflow-y: auto;
  padding-bottom: 2rem;
  scrollbar-width: none;
  -ms-overflow-style: none; /* Older versions of Edge */
}
/* For Chrome, Safari, and newer versions of Opera */
.box_outer_info::-webkit-scrollbar {
  display: none;
}

.box_inner_sections {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;
  flex-shrink: 1;
}

.box_inline_text_image {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.horiz_divider_line {
  width: 1px; /* Changed from 100% to 1px */
  height: 100%; /* Changed from 1.5px to 100% */
  background-color: #b5b3bc;
  margin: 0 1.5rem; /* Changed from 1.5rem 0 to 0 1.5rem */
}
