.nav {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo_div {
  display: flex;
  align-items: center;

  gap: 10px;
}

.logo_text {
  width: 177px;

  font-family: "Montserrat";
  font-style: normal;

  font-weight: 700;
  font-size: 30px;

  /* identical to box height, or 75% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;

  /* Gradient/Gradient 1 */

  background: linear-gradient(150deg, #006ce8 20%, #ff3e95 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  text-shadow: #161616;
}

.logo_svg {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.assets_info_container {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.wallet_address_div {
  position: relative;
  border: 1px solid #f2f0ff;
  border-radius: 8px;
}

.wallet_address_text {
  /* Body 1/Default */

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Text/Text 1 */

  color: #f2f0ff;

  padding: 0.5rem;
  padding-inline: 2.5rem;
}

.wallet_address_label {
  position: absolute;
  top: -8px;
  left: 1rem;
  background: #1e1e1e;
  padding: 0 0.5rem;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height, or 150% */

  /* Text/Text 2 */

  color: #b5b3bc;
}

.wallet_balance_div {
  position: relative;
  border: 1px solid #f2f0ff;
  border-radius: 8px;
  width: 165px;
  text-align: end;
}

.wallet_balance_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #f2f0ff;
  padding: 0.5rem;
}

.wallet_balance_label {
  position: absolute;
  top: -8px;
  left: 1rem;
  background: #1e1e1e;
  padding: 0 0.5rem;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height, or 150% */

  /* Text/Text 2 */

  color: #b5b3bc;
}

.algo {
  width: 20px;
  height: 20px;
}

.balance_info {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-inline: 20px;
  gap: 2px;
}

.helpButton {
  font-family: "Montserrat";
  color: #f8f8f8;
  position: relative;
  border-radius: 8px;
  padding: 12px;
  font-size: 16px;
  font-weight: 800;
  cursor: pointer;
  border: none;
  background-color: #006ce8;
}
