.optinContentWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 100vh; /* Added to center vertically */
  align-items: center; /* Added to center vertically */
}

.optinMessageContainer {
  text-align: center;
  flex-basis: 50%;
  box-sizing: border-box;
  padding: 16px;
  color: #ffffff;
}

.optinQrContainer {
  text-align: center;
  flex-basis: 50%;
  box-sizing: border-box;
  padding: 16px;
  max-width: 40%;
}

.optinQrContainer img {
  width: 75%; /* Reduced size of QR code by 25% */
}

.optinHeaderText,
.optinSubHeaderText,
.optinParagraph {
  color: #ffffff !important; /* Added !important to ensure precedence */ /* Set text color to white */
}

.optinMessageContainer button {
  height: 36px;
  width: 160px;
  border-radius: 100px;
  padding: 6px 32px;
  font-size: 16px;
  border: none;
  background-color: #006ce8;
  color: #ffffff;
  cursor: pointer;
}

.optinLink {
  color: #ffffff;
  background-color: transparent;
  text-decoration: skyblue;
}

@media screen and (max-width: 767px) {
  .optinMessageContainer,
  .optinQrContainer {
    flex-basis: 100%;
  }
}
a {
  color: skyblue;
  background-color: transparent;
  text-decoration: skyblue;
}
.logoutButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #f8f8f8;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
}
.optinButton {
  background-color: #f8f8f8;
  border: none;
  border-radius: 4px;
  padding-top: 30px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 700;
}

.helpButton {
  border-radius: 4px;
  padding: 5px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background-color: #006ce8;
}
.section {
  min-height: 100vh;
}

.logo_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.logo_text {
  width: 177px;

  font-family: "Montserrat";
  font-style: normal;

  font-weight: 700;
  font-size: 40px;

  /* identical to box height, or 75% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;

  /* Gradient/Gradient 1 */

  background: linear-gradient(163deg, #006ce8 0%, #ff3e95 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */

  text-shadow: #161616;
}

.logo_svg {
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.login_div {
  width: 1264px;
  height: 600px;
  padding: 4rem;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  /* Cards 2 */

  background: #161616;
  border-radius: 20px;
}
.sorry_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 38px;

  text-align: center;

  /* Text/Text 1 */

  color: #f2f0ff;
}
.tokenText {
  font-size: 1.2em;
  font-weight: 600;
  color: #f2f0ff; /* White text for better visibility */
  margin-bottom: 5px;
}

.tokenValue {
  font-size: 0.8em;
  font-weight: 400;
  font-style: italic;
  color: #c6c6c6; /* Bright green text for better visibility */
  margin-bottom: 20px;
}
.addressText {
  font-size: 1em;
  font-weight: 400;
  color: #c6c6c6; /* Bright green text for better visibility */
  margin-bottom: 5px;
}
