.section {
  min-height: 100vh;
}
.uploadTitle,
.filesTitle,
.assetsTitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0.01em;
  color: #f2f0ff;
  text-shadow: 2px 4px 12px rgba(0, 0, 0, 0.64);
}

.assetName {
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #b5b3bc;
}

.assetAmount {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #b5b3bc;
}

.searchInput {
  width: 317px;
  height: 40px;
  background: #242323;
  border: 1.5px solid #006ce8;
  box-sizing: border-box;
  border-radius: 50px;
  padding: 7px 1rem;
  color: #f2f0ff;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  padding-left: 2.5rem;
}
.searchBoxContainer {
  position: relative;
}

.uploadContainer,
.filesContainer,
.nftDetailsContainer {
  background-color: #161616;
  border-radius: 20px;
  border: 1px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 1.5rem;
}
.mainContainer {
  padding: 1rem;
  height: calc(100vh - 105px);
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.uploadContainer {
  height: 202px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.filesContainer {
  min-height: calc(100vh - 90px - 202px - 2rem);
  margin-top: 2rem;
}

.nftDetailsContainer {
  height: auto;
  min-height: calc(100vh - 90px - 202px - 2rem);
  padding: 1rem;
}

.searchIcon {
  fill: #0368ff;
}
.searchIconWrapper {
  position: absolute;
  left: 1rem;
  top: 12px;
}

.commonMarginTop {
  margin-top: 1rem;
}
