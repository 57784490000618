.section {
  min-height: 100vh;
}

.logo_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.logo_text {
  width: 177px;

  font-family: "Montserrat";
  font-style: normal;

  font-weight: 700;
  font-size: 40px;

  /* identical to box height, or 75% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;

  /* Gradient/Gradient 1 */

  background: linear-gradient(150deg, #006ce8 20%, #ff3e95 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */

  text-shadow: #161616;
}

/* .logo_bg {
  width: 5rem;
  height: 5rem;
  border-radius: 20px;
  background: #161616;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo_circle {
  width: 49.6px;
  height: 49.6px;
  border-radius: 100%;

  background: linear-gradient(135deg, #0368ff 0%, #ff3e95 100%);
} */

.logo_svg {
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.login_div {
  width: 1264px;
  height: 600px;
  padding: 4rem;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  /* Cards 2 */

  background: #161616;
  border-radius: 20px;
}

.login_text {
  width: 798px;
  height: 90px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 84px;
  line-height: 90px;
  /* identical to box height, or 107% */

  text-align: center;
  letter-spacing: 0.01em;

  /* Text/Text 1 */

  color: #f2f0ff;

  /* Shadow/Extra High */

  text-shadow: 2px 4px 12px rgba(0, 0, 0, 0.64);
}

.login_button_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 32px;
  gap: 10px;

  /* Primary/Default */

  background: #006ce8;
  border-radius: 100px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Text/Text 1 */

  color: #f2f0ff;

  cursor: pointer;
}

.pera {
  width: 36px;
  height: 36px;

  border-radius: 4px;
}

.walletconnect_div {
  display: flex;
  align-items: center;
  gap: 4px;
}

.walletconnect_logo {
  width: 36px;
  height: 14.89px;
}

.walletconnect_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 24px;
  /* or 240% */

  letter-spacing: 0.04em;
  text-transform: capitalize;

  color: #ffffff;
}

.demo_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400; /* Regular weight, not bold */
  font-size: 12px; /* Slightly larger font */
  line-height: 24px;
  letter-spacing: 0.04em;
  margin-top: 20px; /* Adjust this value as needed */
  text-transform: none; /* No text transformation */
  color: #ffffff;
}
